var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import get from "lodash.get";
import { Tooltip } from 'react-tippy';
import { ratingMap, tooltipMap } from "../interfaces/ReviewVenue";
var VenueRating = /** @class */ (function (_super) {
    __extends(VenueRating, _super);
    function VenueRating() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.options = ratingMap;
        _this.tooltipContent = tooltipMap;
        _this.propagateChange = function (selected) {
            _this.props.form.setFieldValue(_this.props.field.name, selected);
        };
        _this.handleBlur = function () {
            _this.props.form.setFieldTouched(_this.props.field.name, true);
        };
        _this.setRating = function (event) {
            _this.props.form.setFieldValue(_this.props.field.name, event.currentTarget.value);
        };
        _this.getStarClass = function (number) {
            var _a;
            var selectedIdx = (_a = get(_this.props.form.values, _this.props.field.name)) !== null && _a !== void 0 ? _a : 0;
            var classes = ["star"];
            if (number <= selectedIdx) {
                classes.push("star--selected");
            }
            return classes.join(' ');
        };
        _this.getStarElement = function (number) {
            var _a;
            var selectedIdx = (_a = get(_this.props.form.values, _this.props.field.name)) !== null && _a !== void 0 ? _a : 0;
            if (number <= selectedIdx) {
                return React.createElement("svg", null,
                    React.createElement("use", { xlinkHref: "#icon--heart-filled" }));
            }
            return React.createElement("svg", null,
                React.createElement("use", { xlinkHref: "#icon--heart-empty-thin" }));
        };
        _this.getSelectedLabel = function () {
            var _a;
            var selectedIdx = (_a = get(_this.props.form.values, _this.props.field.name)) !== null && _a !== void 0 ? _a : 0;
            if (selectedIdx === 0) {
                return React.createElement("span", { className: "ratings__placeholder" }, "Choose a rating above");
            }
            return (React.createElement("div", { className: "ratings__value" },
                React.createElement("span", null, _this.options[selectedIdx - 1].label),
                React.createElement(Tooltip, { position: "bottom", html: (React.createElement("div", { className: "tooltip-inner" },
                        React.createElement("span", { className: "h3" }, _this.tooltipContent[selectedIdx - 1].title),
                        React.createElement("p", null, _this.tooltipContent[selectedIdx - 1].description))), trigger: "click", className: "ratings__tooltip", arrow: true, size: "small", animation: "none" },
                    React.createElement("svg", null,
                        React.createElement("use", { xlinkHref: "#icon--info" })))));
        };
        _this.getTooltipContent = function () {
            var _a;
            var selectedIdx = (_a = get(_this.props.form.values, _this.props.field.name)) !== null && _a !== void 0 ? _a : 0;
            if (selectedIdx === 0) {
                return React.createElement(React.Fragment, null);
            }
            return (React.createElement(React.Fragment, null,
                React.createElement("h3", null, _this.tooltipContent[selectedIdx - 1].title),
                React.createElement("p", null, _this.tooltipContent[selectedIdx - 1].description)));
        };
        _this.render = function () {
            var _a;
            var selectedIdx = (_a = get(_this.props.form.values, _this.props.field.name)) !== null && _a !== void 0 ? _a : 0;
            var getSelectedLabel = _this.getSelectedLabel();
            // const getToolTipContent = this.getTooltipContent();
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "ratings ratings--".concat(selectedIdx) },
                    _this.props.label && (React.createElement("label", { htmlFor: "review-rating", className: "ratings__label" }, _this.props.label)),
                    React.createElement("div", { id: "review-rating", className: "ratings__stars" }, _this.options.map(function (n) {
                        var star = _this.getStarElement(n.value);
                        return (React.createElement("button", { type: "button", key: 'star-' + n.value, value: n.value, className: _this.getStarClass(n.value), onClick: _this.setRating }, star));
                    })),
                    getSelectedLabel)));
        };
        return _this;
    }
    return VenueRating;
}(React.PureComponent));
export default VenueRating;
